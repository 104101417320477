
import React from "react";


export async function FetchData(urlData, errorHandler, )
{
  if(urlData)
  {
    const retVal = await fetch(`${urlData.url}`, {
        cache: "no-cache",
        method: urlData.method,
        mode: urlData.mode,
        crossDomain: urlData.crossDomain,
        headers: urlData.headers,
        body: (urlData.body) ? JSON.stringify(urlData.body) : null,
      })
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => (urlData.jsonResult) ? res.json() : res)
      .then((result) => {
          //errorHandler && errorHandler("");
          return result;
      })
      .catch((error) => {
          switch (error.status) {
              case 400:
                  errorHandler && errorHandler("An error occurred while process this request");
                  break;

              case 401:
                  errorHandler && errorHandler("Your credentials have expired, please login again.");
                  break;

              case 403:
                errorHandler && errorHandler("You do not have the correct admin rights to change this user.");
                break;
  
              default:
                  errorHandler && errorHandler(`error (${error.status}) has occurred, please report to support.`);
                  break;
          }
          return null;
      });

    return retVal;
  }
  else
  {
    errorHandler("No urlData provided for fetch");
    return null;
  }     
}

export async function GetApiKeys(userToken, errorHandler) {

  if (userToken) 
  {
      const apiUrl = "api/apikeys/getall";
      const urlData = {
        url : apiUrl,
        method: "GET",
        mode: "cors",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken,
        },
        body: null,
        jsonResult: true
      }

      const fetchResponse = await FetchData(urlData,(err) => errorHandler(err))
      return fetchResponse;
  }
  else
  {
    errorHandler && errorHandler("Token is invalid");
    return null;
  }
}

export async function GetUserSearchData(userToken, apiKeyId, errorHandler) {

    if (userToken) {
        const apiUrl = `api/searchdata/?apiKeyId=${apiKeyId}`;
        const urlData = {
            url: apiUrl,
            method: "GET",
            mode: "cors",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + userToken,
            },
            body: null,
            jsonResult: true
        }

        const fetchResponse = await FetchData(urlData, (err) => errorHandler(err))
        return fetchResponse;
    }
    else {
        errorHandler && errorHandler("Token is invalid");
        return null;
    }
}

export async function UpdateApiKey(userToken,apiKeyModel,errorHandler) {

    if (userToken && apiKeyModel)
  {
    const apiUrl = "api/apikeys";
    const urlData = {
      url : apiUrl,
      method: "PUT",
      mode: "cors",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken,
      },
      body: {
          "id": apiKeyModel.id,
          "apikey": apiKeyModel.apikey,
          "name": apiKeyModel.name,
          "validfrom": apiKeyModel.validfrom,
          "validto": apiKeyModel.validto,
          "active": apiKeyModel.active,
      },
      jsonResult: false
    };

    const fetchResponse = await FetchData(urlData, (err) => errorHandler(err))

    return fetchResponse;
  }
  else
  {
    errorHandler && errorHandler("Token, apikey details are invalid");
    return null;
  }
}

export async function CreateApiKey(userToken,apiKeyModel,errorHandler) {

    if (userToken && apiKeyModel)
    {
        const apiUrl = `api/apikeys/?keyname=${encodeURIComponent(apiKeyModel.name)}&apikey=${encodeURIComponent(apiKeyModel.apikey)}&validto=${apiKeyModel.validto}&validfrom=${apiKeyModel.validfrom}&active=${apiKeyModel.active}`
      const urlData = {
      url : apiUrl,
      method: "POST",
      mode: "cors",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken,
      },
      jsonResult: false
    };

    const fetchResponse = await FetchData(urlData, (err) => errorHandler(err))

    return fetchResponse;
  }
  else
  {
    errorHandler && errorHandler("Token, apikey details are invalid");
    return null;
  }
}



