import React from "react";
import { Button } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const ContinueButton = ({continueHandler, title, enabled}) =>  {

    return (
        <Button
        disabled={!enabled}
        onClick={continueHandler}
        sx={{
          color: "#2A6EBB",
          "&:hover": {
            backgroundColor: "#00AEEF",
            color: "#FFFFFF",
          },
        }}
        startIcon={<ArrowForwardIcon />}
      >{title ? title : "Continue"}
      </Button>
    );
}

export default ContinueButton;