import React  from "react";
import { DataGrid } from '@mui/x-data-grid';

const AADataTable = ({columnData,rowData, handleDoubleClick}) => {

    return (
        <div style={{ height: '95%', width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                      disableColumnMenu={false}
                      disableColumnSelector={true}
                      disableColumnFilter={false}
                      disableDensitySelector={true}
                      //disableVirtualization={true}
                      disableSelectionOnClick={true}
                      hideFooter={true}
                      columns={!columnData ? [] : columnData}
                      rows={!rowData ? [] : rowData}
                      onCellDoubleClick={(params, event) => {
                            if (handleDoubleClick)
                                handleDoubleClick(params);
                            if (!event.ctrlKey) {
                                event.defaultMuiPrevented = true;
                            }
                        }}
                    />
                </div>
             </div>
         </div>
    );
};

export default AADataTable;