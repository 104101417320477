//#region header */
/**************************************************************************************************
//
//  Description: URL data about the api calls we need to make
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   02.07.21 Sean Flook         WI39??? Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */
import React from "react";
import { env } from './../env'

var currentConfig = {
  securityApi: ""
};

const getConfigInfo = () => {
  if (
    !currentConfig ||
    !currentConfig.securityApi ||
    currentConfig.securityApi.length === 0
  ) {

    currentConfig = {
        securityApi: env.REACT_APP_SECURITY_API
    };
  }

   //console.log("[SF] getConfigInfo", currentConfig);
};

/*
 * 
 * Security Urls 
 * 
 */

const GetSecurityApiSite = (urlController) => {
  getConfigInfo();
  const SecurityApiUrl = `${currentConfig.securityApi}${urlController}`;
  return SecurityApiUrl;
}

export async function FetchData(urlData, errorHandler, )
{
  if(urlData)
  {
    const retVal = await fetch(`${urlData.url}`, {
        cache: "no-cache",
        method: urlData.method,
        mode: urlData.mode,
        crossDomain: urlData.crossDomain,
        headers: urlData.headers,
        body: (urlData.body) ? JSON.stringify(urlData.body) : null,
      })
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => (urlData.jsonResult) ? res.json() : res)
      .then((result) => {
          //errorHandler && errorHandler("");
          return result;
      })
      .catch((error) => {
          switch (error.status) {
              case 400:
                  errorHandler && errorHandler("An error occurred while process this request");
                  break;

              case 401:
                  errorHandler && errorHandler("Your credentials have expired, please login again.");
                  break;

              case 403:
                errorHandler && errorHandler("You do not have the correct admin rights to change this user.");
                break;
  
              default:
                  errorHandler && errorHandler(`error (${error.status}) has occurred, please report to support.`);
                  break;
          }
          return null;
      });

    return retVal;
  }
  else
  {
    errorHandler("No urlData provided for fetch");
    return null;
  }     
}

export async function LoginUser(username, password, errorHandler) {
  const apiUrl = GetSecurityApiSite("/api/v1/Authority/Login");
  //console.log("login user ",username, password, apiUrl);

  const loginDetails = {
    auditname: username,
    password: password,
  };

  const loginRes = await fetch(apiUrl, {
    cache: "no-cache",
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    crossDomain: true,
    body: JSON.stringify(loginDetails),
    })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => res.json())
    .then((result) => {
      //console.log("login result",result);
      return result;
    })
    .catch((error) => 
    {
      //console.log("login error",error);
      switch (error.status) {
        case 400:
          errorHandler && errorHandler("You need to enter a valid username and password, please try again.");
          break;

        case 401:
          errorHandler && errorHandler("Unknown username or password, please try again.");
          break;

        default:
          errorHandler && errorHandler("An unknown error occurred, please report to support.");
          break;
      }
      return null;
    });

    return loginRes;
}

export async function WhoAmI(userToken, errorHandler) {

  if(userToken)
  {
    const apiUrl = await GetSecurityApiSite("/api/v1/Authority/WhoAmI");
    const urlData = {
      url : apiUrl,
      method: "GET",
      mode: "cors",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken,
      },
      body: null,
      jsonResult: true
    }

    const fetchResponse = await FetchData(urlData,errorHandler)
    return fetchResponse;
  }
  else
  {
    errorHandler && errorHandler("Token is invalid");
    return null;
  }  
}

export const GetApiVersion = async (userToken, errorHandler) => {

    if (userToken) {
  
        const apiUrl = await GetSecurityApiSite("/api/v1/version");

        const urlData = {
          url : apiUrl,
          method: "GET",
          mode: "cors",
          crossDomain: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userToken,
          },
          body: null,
          jsonResult: false
        };
  
        const fetchResponse = await FetchData(userToken,urlData,errorHandler)
        return fetchResponse;
    }
    else
    {
      errorHandler && errorHandler("Token is invalid");
      return null;
    }
}



