import React, { useEffect, useState} from "react";
import { StyledWizardDialog, StyledWizardDialogTitle } from "./StyledWizardDialog";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Typography,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";
import { FormRowStyle, FormInputStyle,TextFieldLabel } from "../../utils/AAStyles";
import { useTheme } from '@mui/material/styles';
import ContinueButton from "../../components/continueButton";
import AntSwitch from "../../components/AntSwitch";
import { GetCurrentDate } from "../../utils/HelperUtils";
import { UpdateApiKey, CreateApiKey } from "../../configuration/AAConfig_ApiKeys"


const ApiKeyDetailsDialog = ({ apiKeyDetails, currentToken, closeDialogHandler, updateApiKeyHandler, continueError }) =>  {

    const theme = useTheme();

    const [keyId, setKeyId] = useState(apiKeyDetails ? apiKeyDetails.id : null);
    const [keyName, setKeyName] = useState(apiKeyDetails ? apiKeyDetails.name : "");
    const [apiKey, setApiKey] = useState(apiKeyDetails ? apiKeyDetails.apikey : "");
    const [validFrom, setValidFrom] = useState(apiKeyDetails ? apiKeyDetails.validfrom : GetCurrentDate());
    const [validTo, setValidTo] = useState(apiKeyDetails ? apiKeyDetails.validto : GetCurrentDate());
    const [active, setActive] = useState(apiKeyDetails ? apiKeyDetails.active : true);
    const [errorTest, setErrorText] = useState();

    const isNewUser = !(apiKeyDetails && apiKeyDetails.id.length > 0);


    const nameMeta = { name: "Name", minLength: 5, maxLength: 50, required: true, update: setKeyName, property: keyName, isReadOnly: false};
    const apiKeyMeta = {name : "ApiKey", minLength: 10, maxLength : 100, required: true, update: setApiKey, property:apiKey,isReadOnly:true};
    const activeMeta = { name: "Active", minLength: 5, maxLength: 100, required: true, update: setActive, property: active, isReadOnly: false };

    const validFromMeta = { name: "Valid From", allowPast: false, mustBeFuture:false, required: true, update: setValidFrom, property: validFrom, isReadOnly: false };
    const validToMeta = { name: "Valid To", allowPast: false, mustBeFuture:true, required: true, update: setValidTo, property: validTo, isReadOnly: false };

  
    const [hasContinued, setHasContinued] = useState(false);
    const [contineEnabled, setContinueEnabled] = useState(true);

  
    useEffect(() => {
      validateFields();
  },[keyName,apiKey,validFrom,validTo,active])

  const onCancelHandler = (event,reason) => { 
    if(reason !== "backdropClick") 
      closeDialogHandler();
  };
  
  const handleContinueClick = async () => {
        setHasContinued(true);

        const updatedApiKeyDetails =
        {
            ...apiKeyDetails,
            name: keyName,
            apikey: apiKey,
            validfrom: validFrom,
            validto: validTo,
            active: active
        };

      if (updatedApiKeyDetails && updatedApiKeyDetails.id.length > 0) {
          const response = await UpdateApiKey(currentToken, updatedApiKeyDetails, (err) => setErrorText(err));
          updateApiKeyHandler(response, updatedApiKeyDetails);
      }
      else
      {
          const response = await CreateApiKey(currentToken, updatedApiKeyDetails, (err) => setErrorText(err));
          updateApiKeyHandler(response, updatedApiKeyDetails);
      }

      //updateApiKeyHandler(updatedApiKeyDetails)
};

const editControls = [nameMeta, apiKeyMeta];
const dateControls = [validFromMeta, validToMeta];
 

  const isFieldValid = (meta, value ) => {
    return (value && (value.trim().length >= meta.minLength && value.trim().length < meta.maxLength))
    };

    const isDateValid = (meta, value) => {
        if (!value && meta.required) {
            return false;
        }
        else {
            const dateVal = new Date(value);
            const todayDate = new Date(GetCurrentDate(false));
            const PastOk = (meta.allowPast) || (dateVal >= todayDate);
            const FutureOk = !((meta.mustBeFuture) && (dateVal <= todayDate));
            return (!!keyId || (PastOk && FutureOk));
        }
    };

    
  const getHelperText = (meta, value ) => 
  { 
    return ( hasContinued && !isFieldValid(meta,value)) 
    ? `${meta.name} must be between ${meta.minLength} and ${meta.maxLength} chars`
    : `${meta.maxLength-value.length} chars`;
  }
  
  
  const onApiKeyDetailsChange = (handlerFunc, value, meta) => 
  {
    if(value.length <= meta.maxLength)
      handlerFunc(value)
  }

  const validateFields = () => {
      const controlsValid = editControls.reduce((x, y) => x && isFieldValid(y, `${y.property}`), true);
      setContinueEnabled(controlsValid);
  }

  return (
    <StyledWizardDialog
      onClose={onCancelHandler}
      open
      fullWidth
      disableEnforceFocus
      disableEscapeKeyDown
      maxWidth="sm"
    >
      <StyledWizardDialogTitle id="customized-dialog-title" onClose={onCancelHandler} title={isNewUser ? "New ApiKey Details" : "Update ApiKey Details"}  />
      <DialogContent sx={{ marginTop: theme.spacing(1) }}>
          
          {editControls.map( x =>  {
            return (
            <Grid
              key={x.name}
              container
              justifyContent="flex-start"
              alignItems="center"
              sx={FormRowStyle()}
            >
              <Grid item xs={3}>
                <Typography
                  id={`aa-text-label-${x.name}`}
                  variant="body2"
                  color="initial"
                  align="left"
                  sx={TextFieldLabel(hasContinued && !isFieldValid(x,`${x.property}`)) }
                >
                {x.name}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  id={`aa-text-textfield-${x.name}`}
                  error={hasContinued && !isFieldValid(x,`${x.property}`)}
                  sx={FormInputStyle()}
                  fullWidth
                  required={x.required}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  value={x.property}
                  helperText={getHelperText(x, `${x.property}`)}
                  onChange={(e) => onApiKeyDetailsChange(x.update,e.target.value, x)}
                  aria-labelledby={`aa-text-label-${x.name}`}
                  disabled={x.isReadOnly}
                        />
              </Grid>
            </Grid>
            )
          })}
         {dateControls.map(x => {
                  return (
                      <Grid
                          key={x.name}
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                          sx={FormRowStyle()}
                      >
                          <Grid item xs={3}>
                              <Typography
                                  id={`aa-text-label-${x.name}`}
                                  variant="body2"
                                  color="initial"
                                  align="left"
                                  sx={TextFieldLabel(hasContinued && !isDateValid(x, `${x.property}`))}
                              >
                                  {x.name}
                              </Typography>
                          </Grid>
                          <Grid item xs={9}>
                              <DatePicker
                                  id={`aa-text-textfield-${x.name}`}
                                  label=""
                                  value={new Date(x.property)}
                                  onChange={(newValue) => {
                                          x.update(newValue.toISOString())
                                  }}
                                  renderInput={(params) => <TextField {...params} disabled={true} />}
                              />

                          </Grid>
                      </Grid>
                  )
              })}

            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              sx={FormRowStyle()}
            >
              <Grid item xs={3}>
                <Typography
                  id={`aa-text-label-active`}
                  variant="body2"
                  color="initial"
                  align="left"
                  sx={FormRowStyle()}
                >
                Active
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <AntSwitch onClick={()=>setActive(!active)}  checked={active}  />
              </Grid>  
                <Grid item xs={8}>  
                <Typography>{active ? "Active" : "Disabled"}</Typography>
            </Grid>
            </Grid>

      </DialogContent>

      <DialogActions sx={{ marginBottom: theme.spacing(1) }}>
        <ContinueButton continueHandler={handleContinueClick} enabled={contineEnabled} />
      </DialogActions>
    </StyledWizardDialog>
  );
}

export default ApiKeyDetailsDialog;