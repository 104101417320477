import React, { useState, useEffect, Fragment } from "react";
import { SearchDataTableColumns } from "../../components/Data/DataTableColumns_ApiKey";
import { DataGrid } from '@mui/x-data-grid';

import {DialogContent,Snackbar, Alert} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { StyledWizardDialog, StyledWizardDialogTitle } from "./StyledWizardDialog";


const ApiKeyInfoDialog = ({ closeDialogHandler, searchData }) => {

    const theme = useTheme();

    const [copyAlert, setCopyAlert] = useState(false);
    const [error, setError] = useState();

    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    const onCancelHandler = (event, reason) => {
        if (reason !== "backdropClick")
            closeDialogHandler();
    };

    return (
        <StyledWizardDialog
            onClose={(evt, reason) => onCancelHandler(evt, reason)}
            open
            aria-labelledby="search-data-dialog"
            fullWidth
            disableEnforceFocus
            disableEscapeKeyDown
            maxWidth="md"
        >
            <StyledWizardDialogTitle id="customized-dialog-title" onClose={(e) => onCancelHandler(e)} title="Search Data" />

            <DialogContent sx={{ marginTop: theme.spacing(0) }}>

                <div style={{ height: '50vh', width: '100%' }}>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid
                                disableColumnMenu={false}
                                disableColumnSelector={true}
                                disableColumnFilter={true}
                                disableDensitySelector={true}
                                disableSelectionOnClick={true}
                                hideFooter={true}
                                columns={SearchDataTableColumns}
                                rows={searchData}
                            />
                        </div>
                    </div>
                                </div>


            {!error ? <Fragment /> : <Alert severity="error">{error}</Alert>}

            </DialogContent>

            <Snackbar open={copyAlert} autoHideDuration={2000} onClose={() => setCopyAlert(false)}>
                <Alert onClose={() => setCopyAlert(false)} severity="success" sx={{ width: '100%' }}>
                    Data copied to clipboard
                </Alert>
            </Snackbar>

        </StyledWizardDialog>
    );
}

export default ApiKeyInfoDialog;
