
import React, {useState } from "react";

import { AppBar} from "@mui/material";

import HelpIcon from "@mui/icons-material/HelpOutline";
import { Stack, Tooltip, IconButton } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";

import AAHelpDrawer from "../Drawer/AAHelpDrawer";
import AANavHeader from "./AANavHeader";

import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';

import { ActionIconStyle } from "../utils/AAStyles";


/* #endregion imports */

function AAAppBar(props) {
  const theme = useTheme();

  const [openHelp, setOpenHelp] = useState(false);

  const navBarWidth = 0;

  const handleHelpClick = () => {
    setOpenHelp(!openHelp);
  };
  
  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    alignItems: 'flex-start',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    alignContent: "center",
    justifyContent: "center",
    // Override media queries injected by theme.mixins.toolbar
    '@media all': {
      minHeight: 56,
      Opacity: 100,
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft:22,
      paddingRight:27      
    },
  }));
 

  return (
      <AppBar
        position="fixed"
        color="inherit"
        id="imanage-cloud-app-bar"
        elevation={0}
        sx={{
          /*width: `calc(100% - ${openHelp ? navBarWidth + drawerWidth : navBarWidth}px)`,*/
          width: "100%",
          zIndex: (theme) => theme.zIndex.drawer + 1 ,
          marginLeft: navBarWidth,
          /*marginRight: `${openHelp ? drawerWidth : 0}px`,*/
          transition: `theme.transitions.create(["margin", "width"], {
          easing: ${
            openHelp  ? theme.transitions.easing.easeOut : theme.transitions.easing.sharp
          },
          duration: ${
            openHelp 
              ? theme.transitions.duration.enteringScreen
              : theme.transitions.duration.leavingScreen
          },
        })`,
          borderBottomWidth: "1px",
          borderBottomStyle: "solid",
          borderBottomColor: "#2a6ebb",
        }}
      > 
      <StyledToolbar>
        <AANavHeader />

        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{border:0, marginLeft: 0, marginTop:0.5, paddingBottom:0 }}>
            <Tooltip title="Help & support" arrow placement="bottom-end">
              <IconButton aria-label="help and support" onClick={handleHelpClick} size="large"  >
                <HelpIcon sx={ActionIconStyle()} />
              </IconButton>
            </Tooltip>
          </Stack>


          <AAHelpDrawer open={openHelp} handleDrawerClose={handleHelpClick} />        
      </StyledToolbar>
      </AppBar>
  );
}

export default AAAppBar;