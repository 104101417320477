/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets User Avatar component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   02.07.21 Sean Flook                 Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React, { useContext } from "react";
//import { UserAdminRoute } from "../PageRouting";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Grid, Typography } from "@mui/material";
import { ActionIconStyle } from "../utils/AAStyles";

import { UserContext } from "../context/userContext";
import { GetCurrentUserFirstName } from ".././utils/HelperUtils";

/* #endregion imports */

const AAUserAvatar = (props) => 
{

  const { logoff, currentUser, updateShowLogin } = useContext(UserContext);

  const handleClick = () => {
      updateShowLogin(true);
  };

    return currentUser ?
        (
          <Grid item xs onClick={() => logoff(true)}>
          <Grid container direction="row" alignItems="center" justifyContent="flex-start" aria-label="Login" size="medium">
           <Grid item sx={{ marginLeft:1, marginRight: 1}}>
               <AccountCircleIcon fontSize="medium" sx={ActionIconStyle()} />
           </Grid>
           <Grid item sx={{ marginLeft: 0, marginTop:"0" }}>
             <Typography variant="body1" aria-label="Login" sx={ActionIconStyle()}>
                Sign out {GetCurrentUserFirstName(currentUser)}
             </Typography>
           </Grid>
         </Grid>               
        </Grid>
     ) :
    (
      <Grid item xs onClick={handleClick}>
      <Grid container direction="row" alignItems="center" justifyContent="flex-start" aria-label="Login" size="medium">
       <Grid item sx={{ marginLeft:1, marginRight: 1}}>
           <AccountCircleIcon fontSize="medium" sx={ActionIconStyle()} />
       </Grid>
       <Grid item sx={{ marginLeft: 0, marginTop:"0" }}>
         <Typography variant="body1" aria-label="Login" sx={ActionIconStyle()}>
           Login
         </Typography>
       </Grid>
     </Grid>               
    </Grid>
  );
}

export default AAUserAvatar;
