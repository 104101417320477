import React from "react";
import { FormatDate, FormatBasicDateTime } from "../../utils/HelperUtils";


export const ApiKeyTableColumns = [
    {
        headerName: "Id",
        field: "id",
        sortable: false,
        hide: true,
        filterable:false
    },
    {
        headerName: "Name",
        field: "name",
        sortable: true,
        filterable:true,
        flex: 0.25,
        minWidth: 120,
        width:160,
    },
    {
        headerName: "ApiKey",
        field: "apikey",
        sortable: false,
        filterable:true,
        flex: 0.25,
        minWidth: 120,
        width:160,
    },
    {
        headerName: "From",
        field: "validfrom",
        sortable: true,
        filterable:true,
        flex: 0.25,
        valueFormatter: (param) => { return FormatDate(param.value); },
        minWidth: 120,
        width:160,
    },
    {
        headerName: "To",
        field: "validto",
        sortable: true,
        filterable:true,
        flex: 0.25,
        valueFormatter: (param) => { return FormatDate(param.value); },
        minWidth: 120,
        width:160,
    },
    {
        headerName: "Active",
        field: "active",
        sortable: true,
        filterable: false,
        flex: 0.25,
        minWidth: 120,
        width: 160,
    }
];


export const SearchDataTableColumns = [
    {
        headerName: "Id",
        field: "id",
        sortable: false,
        hide: true,
        filterable: false
    },
    {
        headerName: "Search Type",
        field: "type",
        sortable: true,
        filterable: false,
        flex: 0.25,
        minWidth: 160,
        width: 160,
    },
    {
        headerName: "First",
        field: "earliest",
        sortable: true,
        filterable: false,
        flex: 0.25,
        valueFormatter: (param) => { return FormatBasicDateTime(param.value); },
        minWidth: 130,
        width: 160,
    },
    {
        headerName: "Latest",
        field: "latest",
        sortable: true,
        filterable: false,
        flex: 0.25,
        valueFormatter: (param) => { return FormatBasicDateTime(param.value); },
        minWidth: 130,
        width: 160,
    },
    {
        headerName: "Count",
        headerAlign: "right",
        field: "counter",
        sortable: true,
        filterable: false,
        flex: 0.25,
        minWidth: 100,
        width: 100,
        align: "right",
    }
];


