import React, { Fragment } from "react";

import AANavContent from "../components/AANavContent";
import PageRouting from "../PageRouting";


const MainPage = () => {
    return (
        <Fragment>
        <AANavContent />
        <main
            style={{
                flexGrow: 1,
                paddingLeft: "10px",
                paddingRight: "10px",
            }}
        >
            <PageRouting />
        </main>
        </Fragment>
    );
};

export default MainPage;
