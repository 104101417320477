import React, { useContext, useState, useEffect, Fragment } from "react";
import { useTheme } from '@mui/material/styles';
import {
    Snackbar, Alert, Grid, Box, Button, Card, Typography, CardContent, Backdrop, CircularProgress,
    FormControl, FormLabel, FormControlLabel, Radio, RadioGroup
} from "@mui/material";
import { UserContext } from "../context/userContext";
import { ApiKeyTableColumns } from "../components/Data/DataTableColumns_ApiKey"
import ApiKeyInfoDialog from "../dialogs/apikey/ApiKeyInfoDialog";
import ApiKeyDetailsDialog from "../dialogs/apikey/ApiKeyDetailsDialog";

import ApiKeyListGrid from "../components/apiKeyListGrid";
import { GetApiKeys, GetUserSearchData } from "../configuration/AAConfig_ApiKeys"
import { RndPasswordGenerator } from "../utils/PasswordUtils" 
import { GetCurrentDate, TodayPlus } from "../utils/HelperUtils"

const HomePage = () => {
    const { currentToken, logoff } = useContext(UserContext);
    const theme = useTheme();

    const [errorText, setErrorText] = useState(null);
    const [apiKeyDataComplete, setApiKeyDataComplete] = useState(null);
    const [apiKeyDataView, setApiKeyDataView] = useState(null);
    const [updateFilterData, setUpdateFilterData] = useState(false);
    const [currentApiKeyDataView, setCurrentApiKeyDataView] = useState("all");
    //const [apiKeyId, setApiKeyId] = useState(null);
    const [isDataLoading, setIsDataLoading] = useState(false);
    
    const [apiSearchData, setApiSearchData] = useState();
    const [editedApiKey, setEditedApiKey] = useState();

    const [errorAlert, setErrorAlert] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);

    const [errorMsg, setErrorMsg] = useState();
    const [successMsg, setSuccessMsg] = useState();

    const [isLoading, setIsLoading] = useState(true);
    const [showSearchData, setShowSearchData] = useState();
    const [showEditApiKey, setShowEditApiKey] = useState(false);

    
    //const [editApiKeyAlert, setEditApiKeyAlert] = useState(false);

    useEffect(() => {

        async function GetApiKeyData()
        {
          
          if(isLoading && !apiKeyDataComplete)
          {
            const apiKeyInfo = await GetApiKeys(currentToken, (err) => setErrorText(err));
            setApiKeyDataComplete(apiKeyInfo);
          }   
        };

        if(isLoading && !errorText)
        {
            GetApiKeyData();
        }
        else
        {
            if(errorText)
            {
               console.log("Logging user off because error::",errorText);
               logoff();
            }        
        }

    }, [isLoading]);


    useEffect(() => {
        setShowEditApiKey(!!editedApiKey);
    }, [editedApiKey]);

    useEffect(() => {
        setIsLoading(!apiKeyDataComplete);
        if (apiKeyDataComplete) {
            setUpdateFilterData(true);
            setApiKeyDataView(apiKeyDataComplete);
        }
      },[apiKeyDataComplete]);

    useEffect(() => {

        function ResetApiDataView(data) {
            if (currentApiKeyDataView === "enabled") {
                setApiKeyDataView(data && data.filter((x) => (x.active)));
            }
            else if (currentApiKeyDataView === "disabled") {
                setApiKeyDataView(data && data.filter((x) => (!x.active)));
            }
            else if (currentApiKeyDataView === "expired") {
                setApiKeyDataView(data && data.filter((x) => (new Date(x.validto) <= new Date(GetCurrentDate(false)))));
            }
            else {
                setApiKeyDataView(data);
            }
        }

        if (updateFilterData && apiKeyDataComplete)
            ResetApiDataView(apiKeyDataComplete)

    }, [updateFilterData]);

    useEffect(() => {
       if(apiKeyDataComplete)
        setUpdateFilterData(!apiKeyDataView);
    }, [apiKeyDataView]);

    useEffect(() => {
        if (isDataLoading)
            setIsDataLoading(!apiSearchData);
    }, [apiSearchData]);

    useEffect(() => {
        setUpdateFilterData(!updateFilterData);
    }, [currentApiKeyDataView]);

   
    const onClickSearchInfo = async (e, id) => {
        e.stopPropagation();        
        setIsDataLoading(true);
        const searchData = await GetUserSearchData(currentToken, id, setErrorText);
        setApiSearchData(searchData);
        setShowSearchData(!!searchData);
    };
    
    const ShowInfoButton = (params)  =>
    {
          return <Button onClick={(e) => onClickSearchInfo(e,params.id)}>Info</Button>;
    }

    const apiKeyGridColumns = [...ApiKeyTableColumns,
        {
            field: "",
            headerName: "Searches",
            sortable: false,
            renderCell: ShowInfoButton
        }];


    const updateApiKey = (response, details) => {

        setShowEditApiKey(false)


        if(response && response.status === 200)
        {
            setSuccessMsg(`User ${details.email} has been ${(details && details.id.length > 0) ? "created" : "updated"}`)    
            setSuccessAlert(true);
            setApiKeyDataComplete(null);
        }    
        else if (response && response.status === 204)
        {
        }
        else
        {
            setErrorMsg(`ERROR :: ${details.email} has NOT been ${(details && details.id.length > 0) ? "created" : "updated"}`)                    
            setErrorAlert(true);
        }        

    }

    const handleEditApiKey = (params) => {
        //console.log("handledoubleclick",params)
        if(params && params.row)
            setEditedApiKey({...params.row});
    }

    const handleNewApiKeyClick = () => {
       //console.log("handleNewApiKeyClick");
       setEditedApiKey({
        "id": "",
        "name": "",
        "apikey": RndPasswordGenerator(14),
        "validfrom": TodayPlus(0,0,0),
        "validto": TodayPlus(0,3,0),
        "active": true
        });
    }


    const renderForm = () => {
            return (
                <Fragment>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isDataLoading}>
                    <Box display='flex' justifyContent='center' alignItems='center'>
                        <Typography position='absolute'>Loading Data</Typography>
                        <CircularProgress />
                    </Box>
                </Backdrop>
                <Snackbar open={errorAlert} autoHideDuration={4000} onClose={() => setErrorAlert(false)}>
                    <Alert onClose={()=>setErrorAlert(false)} severity="error" sx={{ width: '100%' }}>
                        {errorMsg}
                    </Alert>
                </Snackbar>

                <Snackbar open={successAlert} autoHideDuration={4000} onClose={() => setSuccessAlert(false)}>
                    <Alert onClose={()=>setSuccessAlert(false)} severity="success" sx={{ width: '100%' }}>
                        {successMsg}
                    </Alert>
                </Snackbar>

            <Grid container spacing={1} sx={{ paddingTop: theme.spacing(10) }} direction="column" justifyContent="space-between" alignItems="left">
                        <Grid item xs><Button onClick={handleNewApiKeyClick}>Add ApiKey</Button></Grid>
                        <Grid item xs>
                        </Grid>

                <Grid item xs>
                {
                    showSearchData ? <ApiKeyInfoDialog closeDialogHandler={() => setShowSearchData(false)} searchData={apiSearchData} /> : <Fragment /> 
                }
                 </Grid>
                <Grid item xs>
                {
                    showEditApiKey ? <ApiKeyDetailsDialog apiKeyDetails={editedApiKey} currentToken={currentToken} closeDialogHandler={() => setEditedApiKey()} updateApiKeyHandler={(response, details) => updateApiKey(response, details)} continueError={errorText}/> : <Fragment /> 
                }
                </Grid>   
                <Grid item xs>
                    <Card variant="outlined" sx={{width:"95%", display:"flex" }}>
                        <CardContent sx={{width:"95%", border:0 }}>
                            {(errorText) ? (<Alert severity="error">{errorText}</Alert>) : (<Fragment />)}
                            <Typography variant="h5" component="div" sx={{ paddingTop: theme.spacing(2) }} >
                                Api Key List
                                    </Typography>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={currentApiKeyDataView}
                                            onChange={(event) => setCurrentApiKeyDataView(event.target.value)}
                                        >
                                            <FormControlLabel value="all" control={<Radio />} label="All" />
                                            <FormControlLabel value="enabled" control={<Radio />} label="Enabled" />
                                            <FormControlLabel value="disabled" control={<Radio />} label="Disabled" />
                                            <FormControlLabel value="expired" control={<Radio />} label="Expired" />
                                        </RadioGroup>
                                    </FormControl>

                            <ApiKeyListGrid columns={apiKeyGridColumns} rows={apiKeyDataView} height={"80vh"} onDoubleClick={(params)=>handleEditApiKey(params)} />                        
                        </CardContent>
                    </Card>
                </Grid> 
            </Grid>
        </Fragment>   
         );
    };

    const renderBasic = () => {
        return (
            <Grid container spacing={1} sx={{ paddingTop: theme.spacing(50) }} direction="column" justifyContent="space-between" alignItems="left">
            <Grid item>
                <h1>Loading data, please wait...</h1>
            </Grid>
            </Grid>
        );
    };


    const renderPage = () => {
        return (!isLoading && currentToken) ? renderForm() :  renderBasic();
    }

    return renderPage();
};

export default HomePage;
