/* #region header */
/**************************************************************************************************
//
//  Description: Helper utilities
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   13.07.21 Sean Flook         WI39??? Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

function getDayString(day) {
  switch (day) {
    case 0:
      return "Sun";
    case 1:
      return "Mon";
    case 2:
      return "Tue";
    case 3:
      return "Wed";
    case 4:
      return "Thu";
    case 5:
      return "Fri";
    default:
      return "Sat";
  }
}

function getMonthString(month) {
  switch (month) {
    case 0:
      return "Jan";
    case 1:
      return "Feb";
    case 2:
      return "Mar";
    case 3:
      return "Apr";
    case 4:
      return "May";
    case 5:
      return "Jun";
    case 6:
      return "Jul";
    case 7:
      return "Aug";
    case 8:
      return "Sep";
    case 9:
      return "Oct";
    case 10:
      return "Nov";
    default:
      return "Dec";
  }
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function TodayPlus(addedYears, addedMonth, addedDays) {
    const today = new Date();
    const todayPlusYears = new Date(today.getFullYear() + addedYears, today.getMonth(), today.getDate());
    const todayPlusMonths = new Date(todayPlusYears.getFullYear(), todayPlusYears.getMonth() + addedMonth, todayPlusYears.getDate());
    const todayPlusDays = new Date(todayPlusMonths.getFullYear(), todayPlusMonths.getMonth(), todayPlusMonths.getDate() + addedDays);
    const dd = String(todayPlusDays .getDate()).padStart(2, "0");
    const mm = String(todayPlusDays.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = todayPlusDays.getFullYear();
    return yyyy + "-" + mm + "-" + dd + "T00:00:00";
}


export function GetCurrentDate(includeTime = false) {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  // console.log("DEBUG GetCurrentDate", yyyy + "-" + mm + "-" + dd + "T00:00:00");
  if (includeTime) {
    const hh = String(today.getHours()).padStart(2, "0");
    const nn = String(today.getMinutes()).padStart(2, "0");
    const ss = String(today.getSeconds()).padStart(2, "0");
    return yyyy + "-" + mm + "-" + dd + "T" + hh + ":" + nn + ":" + ss;
  } else return yyyy + "-" + mm + "-" + dd + "T00:00:00";
}

function isToday(dtObj)
{
  const today = new Date();
  const istodayDay = (today.getDate() === dtObj.getDate());
  const istodayMonth = (today.getMonth() === dtObj.getMonth());
  const istodayYear = (today.getFullYear() === dtObj.getFullYear());

  return (istodayDay && istodayMonth && istodayYear);
}

export function FormatDate(params) {
  // console.log("DEBUG FormatDate", params);

  if (params) {
    // console.log("DEBUG FormatDate today", todayDay, todayMonth, todayYear);

    let paramsDate;
    if (params.value) paramsDate = new Date(params.value);
    else paramsDate = new Date(params);
    const luDay = paramsDate.getDate();
    const luMonth = paramsDate.getMonth();
    const luYear = paramsDate.getFullYear();
    // console.log("DEBUG FormatDate params", luDay, luMonth, luYear);

    // if param dates is today show AM/PM time
    if (isToday(paramsDate))
      return `Today, ${formatAMPM(paramsDate)}`;

    const today = new Date();  
    const todayMonth = today.getMonth();
    const todayYear = today.getFullYear();
    //if param date is in the same month and year, but not today
    //if (luMonth === todayMonth && luYear === todayYear)
    //  return `${getDayString(paramsDate.getDay())} ${luDay} ${getMonthString(
    //    luMonth
   //   )}`;

    //if param date is same year but not same month
    //if (luYear === todayYear) return `${luDay} ${getMonthString(luMonth)}`;

    return `${luDay} ${getMonthString(luMonth)} ${String(luYear)}`;
  } else {
    return "";
  }
}

export function FormatDateTime(params) {
  if (params) {
    let paramsDate;
    if (params.value) paramsDate = new Date(params.value);
    else paramsDate = new Date(params);
    // console.log("DEBUG FormatDate params", luDay, luMonth, luYear);
     if (isToday(paramsDate))
        return `Today, ${formatAMPM(paramsDate)}`;

    return `${getDayString(paramsDate.getDay())} ${paramsDate.getDate()} ${getMonthString(paramsDate.getMonth())}, ${formatAMPM(paramsDate)}`;
  } else {
    return "";
  }
}

export function FormatBasicDateTime(params) {
    if (params) {
        let paramsDate;
        if (params.value) paramsDate = new Date(params.value);
        else paramsDate = new Date(params);
        // console.log("DEBUG FormatDate params", luDay, luMonth, luYear);
        if (isToday(paramsDate))
            return `Today, ${formatAMPM(paramsDate)}`;

        return `${paramsDate.getDate()} ${getMonthString(paramsDate.getMonth())} ${paramsDate.getFullYear()}, ${formatAMPM(paramsDate)}`;
    } else {
        return "";
    }
}


export function ConvertDate(date) {
  // console.log("[SF] ConvertDate", date.toString());
  if (!date || date.toString().includes("T00:00:00")) return date;

  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  const yyyy = date.getFullYear();

  return yyyy + "-" + mm + "-" + dd + "T00:00:00";
}

export function GetCurrentUserFirstName(currentUser) {
  return currentUser
    ? `${currentUser.firstName ? currentUser.firstName : ""}`
    : "";
}

export function GetCurrentUserName(currentUser) {
  return currentUser
    ? `${currentUser.firstName ? currentUser.firstName  : ""} ${currentUser.lastName ? currentUser.lastName : ""}`
    : "";
}

